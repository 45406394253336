.modalPrecificador{
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 99;
}

a{
    cursor: pointer;
}

.modalPrecificador-fechar{
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
    margin-top: 20px;
}
.modalPrecificador-btn-fechar{
    border-radius: 15px;
    background-color:transparent;
}

.modalPrecificador-container{
    display: flex;
    justify-content:space-evenly;
    position: fixed;
    column-gap: 10px;
    width: 60%;
    background-color: #FFF;
    top:15%;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 4em 2rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 1);
    border-radius: 16px;
}

.modalPrecificador-container h1{
    text-align: center;
    font-size: 30px;
}

.modalPrecificador-componentes{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    align-items: center;
    justify-content: center;
}
.modalPrecificador-preview{
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
}

.modalPrecificador-componentes h3{
    font-size: 25px;
    text-align: center;
}

.adicionar-itens-header{
    display: flex;
    column-gap: 15px;
}

.pesquisar-container,.sel-categoria-container{
    text-align: center;
    width: 200px;
    height: 100px;
}

.input-pesquisa-container{
    display: flex;
    justify-content: center;
    border: 1px solid black;
    border-radius: 9px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    height: 35px;
    padding: 5px;
    padding-left: 10px;
}

.input-pesquisa-container input {
    width: 100%;
}

.sel-categoria{
    text-align: center;
    border-radius: 9px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    width: 100%;
    height: 35px;
    border: 1px solid black;
}

.sel-produto-container{
    width:100%;
}

.label{
    display: flex;
    text-align: start;
}

.sel-produto{
    min-width: 200px;
    width: 100%;
    text-align: left;
    border-radius: 9px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
}


.modalPrecificador-button{
    display: flex;
}
.modalPrecificador-button button{
    width: 120px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
    background-color: #DC4405;
    border: 0;
    padding: 5px;
    border-radius: 9px;
    color: #FFF;
    transition: 0.1s;
}
.modalPrecificador-button button:hover{
    background-color: #FFF;
    color: #F28F1A;
}

.modalPrecificador-button1{
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalPrecificador-button1 button{
    width: 120px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
    background-color: #DC4405;
    border: 0;
    padding: 5px;
    border-radius: 9px;
    color: #FFF;
    transition: 0.1s;
}
.modalPrecificador-button1 button:hover{
    background-color: #FFF;
    color: #F28F1A;
}

.modalPrecificador-button1 a{
    display: flex;
    border-radius: 9px;
    color: #FFF;
    width: 120px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
    text-decoration: none;
    align-items:center;
    justify-content: center;
    background-color: #DC4405;
    transition: all 0.1s;
}

.modalPrecificador-button1 a:hover{
    background-color: #FFF;
    color: #F28F1A;
}

.modalPrecificador-preview{
    display: flex;
    flex-direction: column;
    height: 350px;
}

.modalPrecificador-preview-container{
    display: flex;
    flex-direction: column;
}

.modalPrecificador-preview-itens{
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    width: 300px;
}
.modalPrecificador-preview-areaItem{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color:  #FFFFFF;
    border: 1px solid black;
    border-radius: 9px;
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    padding: 10px;
    color: #090909;

    .frase_promocional{
        position: absolute;
        top: 57px;
        left: 10px;
        width: 70px;
        font-size: 10px;
    }

    .alcool{
        position: absolute;
        top: 57px;
        right: 10px;
        width: 30px;
        font-size: 10px;
    }
}

.modalPrecificador-preview-container-btn{
    display: flex;
    border-radius: 9px;
    color: #FFF;
    width: 120px;
    height: 30px;
    margin-top: 5px;
    margin-left: 5px;
    text-decoration: none;
    align-items:center;
    justify-content: center;
    background-color: #DC4405;
    transition: all 0.1s;
}

.modalPrecificador-preview-container-btn:hover{
    background-color: #FFF;
    color: #F28F1A;
}

.modalPrecificador-preview-areaItem h1{
    font-size: 25px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.modalPrecificador-preview-areaItem h2{
    font-size: 15px;
    font-weight:normal;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.item-baixo{
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

.item-baixo h3{
    font-size: 40px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.item-baixo h4{
    font-size: 17px;
    margin-top: 5px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}
.textPeso h2{
    font-size: 12px;
    font-weight: normal;
    margin-left: 110px;
    margin-top: -32px;
}

.diminuir{
    margin-top: 5px;
    h2{
        font-size: 16px !important;
    }
    h3{
        font-size: 16px !important;
        margin-top: 0px;
    }
    h4{
        font-size: 16px !important;
        margin-top: 0px;
        margin-right: 10px;
    }
}

.right{
    h2{ 
        margin-left: 160px !important; 
        margin-top: -20px;
    }
}

.alteraPreco{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;
}

.inputAlterarPreco{
    width: 100%;
    text-align: center;
}

@media screen and (max-width:1200px) {
    .modalPrecificador-container{
        justify-content: initial;
        width: 80%;
        height: 80%;
        overflow-y: scroll;
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }
    .modalPrecificador-componentes{
        margin-bottom: 20px;
    }
    .modalPrecificador-container h1{
        text-align: center;
        font-size: 28px;
    }

    .modalPrecificador-componentes h3{
        font-size: 20px;
    }

    .adicionar-itens-header{
        display: flex;
        flex-direction: column;
        height: 150px;
    }

    .sel-categoria{
        min-width: 200px;
        max-width: 225px;
        height: 35px;
        text-align: center;
        border-radius: 9px;
        margin-top: 5px;
        margin-bottom: 5px;
    }

    .modalPrecificador-button button{
        width: 100px;
        height: 25px;
        font-size: 15px;
    }
    
    .modalPrecificador-button1 button{
        width: 100px;
        height: 25px;
        font-size: 15px;
    }
    .modalPrecificador-preview{
        width: 70%;
    }
    .modalPrecificador-preview-itens{
        width: 100%;
    }
}